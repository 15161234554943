<template>
    <div class="content-wrapper cv-section">
        <h1>{{ $t('Edit Courses') }}</h1>

        <form @submit.prevent="save">
            <template v-for="(course, index) in items" :key="index">
                <div :class="['labeled-icon-input', {'has-error': errors[`courses.${index}.name`]}]">
                
                    <div class="form-group">
                        <label :for="`name_${index}`">{{ $t('Name') }}</label>
                        <input v-model="items[index].name" class="form-control" type="text" :id="`name_${index}`">
                        <label :for="`name_${index}`" class="icon-label">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                <g>
                                    <g>
                                        <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                    </g>
                                </g>
                            </svg>
                        </label>
                    </div>

                    <FormErrors v-if="errors[`courses.${index}.name`]" :errors="errors[`courses.${index}.name`]" />

                </div>

                <div :class="['labeled-icon-input', {'has-error': errors[`courses.${index}.year`]}]">
                    
                    <div class="form-group">
                        <label :for="`year_${index}`">{{ $t('Year') }}</label>
                        <input type="number" v-model="items[index].year" class="form-control" :id="`year_${index}`" />
                        <label :for="`year_${index}`" class="icon-label">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                <g>
                                    <g>
                                        <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                    </g>
                                </g>
                            </svg>
                        </label>
                    </div>

                    <FormErrors v-if="errors[`courses.${index}.year`]" :errors="errors[`courses.${index}.year`]" />

                </div>

                <div :class="['labeled-icon-input', {'has-error': errors[`courses.${index}.description`]}]">
                    
                    <div class="form-group textarea-form-group">
                        <textarea v-model="items[index].description" :placeholder="$t('Enter Description')" class="form-control"></textarea>
                    </div>

                    <FormErrors v-if="errors[`courses.${index}.description`]" :errors="errors[`courses.${index}.description`]" />

                </div>
                <button type="button" class="btn btn-grey" @click="deleteItem(index)">
                    {{ $t('Remove') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17.764" viewBox="0 0 15 17.764">
                        <g>
                            <path d="M8.591 17.728a2.419 2.419 0 0 0 2.419 2.317h7.38a2.419 2.419 0 0 0 2.419-2.317l.475-10.939H8.116zm7.9-8.6a.605.605 0 0 1 1.21.039l-.279 7.933a.6.6 0 0 1-.6.584.6.6 0 0 1-.6-.626zm-4.207-.581a.6.6 0 0 1 .626.587l.281 7.926a.6.6 0 0 1-.6.626.6.6 0 0 1-.6-.584l-.266-7.935a.6.6 0 0 1 .569-.62zM21.6 4.37h-4.036V2.885a.6.6 0 0 0-.6-.6h-4.523a.6.6 0 0 0-.6.6V4.37H7.8a.6.6 0 0 0 0 1.21h13.8a.6.6 0 1 0 0-1.21zm-8.549-.88h3.308v.88h-3.313z" transform="translate(-7.2 -2.28)"/>
                        </g>
                    </svg>
                </button>
            </template>

            <button @click="add" type="button" class="btn btn-transparent-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                    <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
                </svg>
                {{ $t('Add more') }}
            </button>
            <button class="btn btn-primary" type="submit">{{ $t('Save') }}</button>
        </form>
    </div>
</template>

<script>
import { cvService } from "@/services/cvService";
import CvSectionMixin from "@/mixins/CvSection";

export default {
    name: "EditCourses",
    mixins: [ CvSectionMixin ],
    data() {
        return {
            updateService: cvService.updateCourses,
            getService: cvService.courses,
            items: [
                {
                    name: '',
                    year: '',
                    description: ''
                }
            ],
        }
    },
    methods: {
         getNewRecords() {
            return {
                'courses': this.items
            }
        },
        getSingleItem() {
            return {
                name: '',
                year: '',
                description: ''
            }
        }
    }
}
</script>